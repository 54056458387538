<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goUpload"
        >
          Upload Image Application
        </b-button>
      </b-col>
      <b-col md="12">
        <b-card title="Call A Car Setting">
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Minute :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="callACarSetting.minute"
              placeholder="Minute"
            />

          </b-form-group>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('callACarSetting')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Mobile Application">
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Dev :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['dev'] != null ? respData['dev'] : false"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('dev', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["dev"] != null
                  ? respData["dev"] === true
                    ? "อยู่ในการพัฒนา"
                    : "สาธารณะ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>

          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Maintenance :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['maintenance'] != null
                  ? respData['maintenance']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('maintenance', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["maintenance"] != null
                  ? respData["maintenance"] === true
                    ? "อยู่ในระหว่างการซ่อมบำรุง"
                    : "สาธารณะ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>

          <b-row class="mt-1">
            <b-card-text class="mr-2">
              InReview :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['inReview'] != null ? respData['inReview'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('inReview', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["inReview"] != null
                  ? respData["inReview"] === true
                    ? "อยู่ในระหว่างการอัพโหลดแอปขึ้นสโตร์"
                    : "ไม่ได้อัพโหลดแอปขึ้นสโตร์"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>

          <b-row class="mt-1">
            <b-card-text class="mr-2">
              CheckUpdate :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['checkUpdate'] != null
                  ? respData['checkUpdate']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('checkUpdate', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["checkUpdate"] != null
                  ? respData["checkUpdate"] === true
                    ? "แอปขึ้นสโตร์สำเร็จ"
                    : "ยังไม่ได้แอปขึ้นสโตร์"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="API Maps">
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Average :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['apiMapsSetting'] != null
                  ? respData['apiMapsSetting']['average']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('apiMapsSetting.average', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["apiMapsSetting"] != null
                  ? respData["apiMapsSetting"]["average"] === true
                    ? "เฉลี่ยการใช้ API"
                    : "ไม่เฉลี่ย"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>

          <b-row class="mt-1">
            <b-card-text class="mr-2">
              StatusNoMoreThan :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['apiMapsSetting'] != null
                  ? respData['apiMapsSetting']['statusNoMoreThan']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('apiMapsSetting.statusNoMoreThan', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["apiMapsSetting"] != null
                  ? respData["apiMapsSetting"]["statusNoMoreThan"] === true
                    ? "จำกัดจำนวนครั้งการใช้ต่อ 1 คีย์"
                    : "ไม่จำกัดจำนวนการใช้"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-form-group
            v-if="
              respData['apiMapsSetting'] != null &&
                respData['apiMapsSetting']['average'] !== true
            "
            label-cols="2"
            label-cols-lg="1"
            label="Main :"
            label-for="input-lg"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
          </b-form-group>
          <b-form-group
            v-if="
              respData['apiMapsSetting'] != null &&
                respData['apiMapsSetting']['statusNoMoreThan'] === true
            "
            label-cols="2"
            label-cols-lg="2"
            label="NoMoreThan :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              placeholder="NoMoreThan"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Mobile Color">
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Primary Color :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="primaryColor"
              placeholder="Primary Color"
              type="color"
            />
            <b-form-input
              id="input-lg"
              v-model="primaryColor"
              placeholder="Primary Color"
            />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Secondary Color :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="secondaryColor"
              placeholder="Secondary Color"
              type="color"
            />
            <b-form-input
              id="input-lg"
              v-model="secondaryColor"
              placeholder="Secondary Color"
            />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Background Color :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="backgroundColor"
              placeholder="background Color"
              type="color"
            />
            <b-form-input
              id="input-lg"
              v-model="backgroundColor"
              placeholder="background Color"
            />
          </b-form-group>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('color')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card title="System">
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Bank :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['bank'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.bank', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["bank"] == true
                    ? "เปิดระบบธนาคาร"
                    : "ปิดระบบธนาคาร"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Money :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['money'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.money', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["money"] == true
                    ? "เปิดระบบเงินสด"
                    : "ปิดระบบเงินสด"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Promptpay :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['promptpay']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.promptpay', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["promptpay"] == true
                    ? "เปิดระบบพร้อมเพย์"
                    : "ปิดระบบพร้อมเพย์"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Credit :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['credit']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.credit', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["credit"] == true
                    ? "เปิดระบบเครดิต"
                    : "ปิดระบบเครดิต"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Paypal :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['paypal']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.paypal', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["paypal"] == true
                    ? "เปิดระบบเพย์พาล"
                    : "ปิดระบบเพย์พาล"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="mr-2">
              Wallet :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['wallet']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.wallet', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["wallet"] == true
                    ? "เปิดระบบกระเป๋าเงิน"
                    : "ปิดระบบกระเป๋าเงิน"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Firebase Messaging">
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="ServerKey :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              placeholder="ServerKey"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Omise">
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="PublicKey :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="omiseSetting.publicKey"
              placeholder="PublicKey"
            />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="SecretKey :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="omiseSetting.secretKey"
              placeholder="SecretKey"
            />
          </b-form-group>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('omiseSetting')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="ThaiBulkSms">
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Username :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="otpSetting.username"
              placeholder="Username"
            />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Password :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="otpSetting.password"
              placeholder="Password"
            />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="Minute :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="otpSetting.minute"
              placeholder="Minute"
            />
          </b-form-group>
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            label="OtpCount :"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="otpSetting.otpCount"
              placeholder="OtpCount"
            />
          </b-form-group>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('otpSetting')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
          <b-form-tags
            v-model="otpSetting.message"
            no-outer-focus
          >
            <template
              #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }"
            >
              <b-card-text class="mr-2">
                Message :
              </b-card-text>
              <span
                v-for="(otp,index) in otpSetting.message"
                :key="index"
              >{{ otp }}</span>
              <br>
              <b-row>
                <b-button
                  size="sm"
                  variant="primary"
                  class="ml-2 mr-2"
                >
                  <span class="mr-25 align-middle">  OTP </span>
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  class="mr-2"
                >
                  <span class="mr-25 align-middle">  REF </span>
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  class="mr-2"
                >
                  <span class="mr-25 align-middle">  MINUTE </span>
                </b-button>
              </b-row>

              <b-input-group aria-controls="my-custom-tags-list">
                <input
                  v-bind="inputAttrs"
                  placeholder="เพิ่มข้อความใหม่"
                  class="form-control"
                  v-on="inputHandlers"
                >
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="addTag()"
                  >
                    {{ $t("Add") }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-col cols="12">
                <draggable
                  v-model="otpSetting.message"
                  tag="ul"
                  class="list-group cursor-move"
                >
                  <b-list-group-item
                    v-for="(tag, index) in tags"
                    :key="index"
                    tag="li"
                  >
                    <div class="d-flex">
                      <!-- <b-avatar :text="listItem.name.slice(0,2)" /> -->
                      <div class="ml-25">
                        <b-card-text class="mb-0">
                          {{ tag }}
                        </b-card-text>
                        <b-button
                          variant="link"
                          size="sm"
                          :aria-controls="`my-custom-tags-tag_${tag.replace(
                            /\s/g,
                            '_'
                          )}_`"
                          class="py-0"
                          @click="removeTag(tag)"
                        >
                          {{ $t("Delete") }}
                        </b-button>
                      </div>
                    </div>
                  </b-list-group-item>
                  <!-- <b-row
                    v-for="(tag,index) in tags"
                    :key="index"
                  >
                    <b-card
                      :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                      tag="li"
                      class="shadow-none border mt-1 mr-1 ml-1 mb-0"
                    >
                      <strong>{{ "[ "+ (index+1) + " ]" }} {{ tag }}</strong>
                      <b-button
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        class="py-0"
                        @click="removeTag(tag)"
                      >
                        {{ $t('Delete') }}
                      </b-button>
                    </b-card>
                  </b-row> -->
                </draggable>
              </b-col>
            </template>
          </b-form-tags>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  BListGroupItem,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/root/settings'

const STORE_MODULE_NAME = 'settings'

export default {
  components: {
    vSelect,
    draggable,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    BListGroupItem,
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Color
      primaryColor: '#23A8C6',
      secondaryColor: '#EFFCFF',
      backgroundColor: '#EFFCFF',
      // API MAPS
      main: '',
      noMoreThan: '',
      callACarSetting: {
        minute: 0,
      },
      // otpSetting
      otpSetting: {
        username: '',
        password: '',
        minute: 0,
        otpCount: 0,
        message: [
          'เพื่อการยืนยันรหัส ป้อนรหัสยืนยัน ',
          '$OTP_CODE',
          ' รหัสอ้างอิง #',
          '$REF',
          ' มีอายุการใช้งาน ',
          '$MINUTE',
          ' นาที',
        ],
      },
      // omiseSetting
      omiseSetting: {
        publicKey: '',
        secretKey: '',
      },
      option: [
        { title: 'Square' },
        { title: 'Rectangle' },
        { title: 'Rombo' },
        { title: 'Romboid' },
      ],
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        if (result.data.data.callACarSetting != null) {
          this.callACarSetting.minute = result.data.data.callACarSetting.minute
        }
        if (result.data.data.color != null) {
          this.primaryColor = `#${result.data.data.color.primaryColor}`
          this.secondaryColor = `#${result.data.data.color.secondaryColor}`
          this.backgroundColor = `#${result.data.data.color.backgroundColor}`
        }
        if (result.data.data.apiMapsSetting != null) {
          this.main = result.data.data.apiMapsSetting.main
          this.noMoreThan = result.data.data.apiMapsSetting.noMoreThan
        }
        if (result.data.data.otpSetting != null) {
          this.otpSetting.username = result.data.data.otpSetting.username
          this.otpSetting.password = result.data.data.otpSetting.password
          this.otpSetting.message = result.data.data.otpSetting.message
          this.otpSetting.minute = result.data.data.otpSetting.minute
          this.otpSetting.otpCount = result.data.data.otpSetting.otpCount
        }
        if (result.data.data.omiseSetting != null) {
          this.omiseSetting.publicKey = result.data.data.omiseSetting.publicKey
          this.omiseSetting.secretKey = result.data.data.omiseSetting.secretKey
        }
        console.log('fetch Success : ', result)
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    goUpload() {
      this.$router.push({ path: '/root/setting/upload' })
    },
    inputChange(name) {
      const obj = {
        color: null,
        apiMapsSetting: null,
        otpSetting: null,
      }
      if (name === 'callACarSetting') {
        obj.callACarSetting = this.callACarSetting
      } else
      if (name === 'color') {
        obj.color = {
          primaryColor: this.primaryColor,
          secondaryColor: this.secondaryColor,
          backgroundColor: this.backgroundColor,
        }
      } else if (name === 'apiMapsSetting') {
        obj.apiMapsSetting = {
          main: this.main,
          noMoreThan: this.noMoreThan,
        }
      } else if (name === 'otpSetting') {
        obj.otpSetting = this.otpSetting
      } else if (name === 'omiseSetting') {
        obj.omiseSetting = this.omiseSetting
      }
      console.log(name)

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
        })
    },
    switchChange(name, status) {
      console.log(name, status)
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
